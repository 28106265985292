import { useEffect, useCallback } from 'react';
import {
  Grid,
  GridItem,
  Container,
  Box,
  Divider,
  Stack,
  Center,
  Image,
  Input,
  FormControl,
  FormErrorMessage,
  Flex,
  Spinner,
  Text,
  HStack,
} from '@chakra-ui/react';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import ev from 'email-validator';
import { format } from 'date-fns';
import { AsyncState } from '../../types/state';
import useAppSelector from '../../redux/hooks/useAppSelector';
import useAppDispatch from '../../redux/hooks/useAppDispatch';
import * as campaignThunk from '../../redux/thunks/campaign';
import * as claimAction from '../../redux/actions/claim';
import * as claimThunk from '../../redux/thunks/claim';
import Header from '../../components/Header';
import ConfirmButton from '../../components/ConfirmButton';
import Exception from '../../containers/Exception';

export default function Claim() {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const campaign = useAppSelector((state) => state.campaign.campaign);
  const issuerInfo = useAppSelector(
    (state) => state.campaign.campaign?.issuer_info
  );
  const tokenInfo = useAppSelector(
    (state) => state.campaign.campaign?.token_info
  );

  const getCampaignByIdStatus = useAppSelector(
    (state) => state.campaign.getCampaignByIdStatus
  );
  const getCampaignByIdException = useAppSelector(
    (state) => state.campaign.getCampaignByIdException
  );
  const claimMintLinkException = useAppSelector(
    (state) => state.claim.claimMintLinkException
  );
  const claimMintLinkStatus = useAppSelector(
    (state) => state.claim.claimMintLinkStatus
  );
  const campaignId = searchParams.get('c');
  const tokenId = searchParams.get('t');
  const signature = searchParams.get('s');
  const tokenVer = searchParams.get('v');
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: async ({ email }) => {
      try {
        await dispatch(
          claimThunk.claimMintLink({
            c: campaignId as string,
            t: tokenId as string,
            s: signature as string,
            e: email,
          })
        ).unwrap();

        navigate('/verify', { replace: true });
      } catch (e: any) {
        if (e.code === 40000) {
          formik.setFieldError('email', e.msg);
          return;
        }
      }
    },
    validate: ({ email }) => {
      if (!ev.validate(email)) {
        return {
          email: 'Invalid email address',
        };
      }
    },
  });

  const getCampaign = useCallback(async () => {
    await dispatch(
      campaignThunk.getCampaignById(campaignId as string)
    ).unwrap();
    dispatch(
      claimAction.storeMintLinkData({
        c: campaignId as string,
        t: tokenId as string,
        e: '',
        s: signature as string,
      })
    );
    dispatch(
      claimAction.storeReturnUrl(`${location.pathname}${location.search}`)
    );
  }, [campaignId, dispatch, location, signature, tokenId]);

  useEffect(() => {
    getCampaign();
  }, [getCampaign]);

  if (getCampaignByIdStatus === AsyncState.PENDING) {
    return (
      <Container maxW={'2560px'} p={0}>
        <Flex justifyContent={'center'} alignItems={'center'} h={'100vh'}>
          <Spinner size={'xl'} color={'coap.blue.5'} thickness={'5px'} />
        </Flex>
      </Container>
    );
  }

  if (getCampaignByIdException?.code === 40004) {
    return (
      <Exception
        code={getCampaignByIdException?.code}
        message={'Campaign Not Found'}
      />
    );
  }

  if (claimMintLinkException?.code === 40001) {
    return (
      <Exception
        code={claimMintLinkException?.code}
        message={claimMintLinkException?.msg}
      />
    );
  }

  return (
    <Container maxW={'2560px'} p={0} pb={150}>
      <Header campaign={campaign} />
      <Stack direction={['column', 'column', 'row']} w={'full'} pt={'50px'}>
        <Box w={['full', 'full', '425px']} maxW={['full', 'full', '425px']}>
          <Text
            color={'coap.blue.5'}
            fontWeight={'light'}
            fontSize={'2xl'}
            p={[
              '5px 20px 15px 20px',
              '5px 20px 15px 20px',
              '5px 20px 15px 50px',
            ]}
          >
            Campaign Details
          </Text>
          <Box
            bgColor={'coap.green.1'}
            borderRightRadius={'1000px'}
            p={['5px 20px 5px 20px', '5px 20px 5px 20px', '5px 20px 5px 50px']}
            mb={'20px'}
            mr={'30px'}
          >
            <Text color={'#ffffff'} fontSize={'2xl'} fontWeight={'semibold'}>
              {campaign?.campaign_info.title}
            </Text>
          </Box>
          <Box
            p={['5px 20px 5px 20px', '5px 20px 5px 20px', '5px 20px 5px 50px']}
            color={'black'}
          >
            <Text fontWeight={'light'} mb={'25px'}>
              {campaign?.campaign_info.content}
            </Text>
            <Text>COAP Token Issuer</Text>
            <Text fontWeight={'light'} mb={'25px'}>
              {issuerInfo?.name}
            </Text>
            <HStack w={'full'} gap={'15px'} mb={'25px'}>
              <Box>
                <Text>Start Date</Text>
                <Text fontWeight={'light'}>
                  {campaign?.claim_period.start &&
                    format(
                      new Date(campaign?.claim_period.start),
                      'yyyy MMM dd'
                    )}
                </Text>
              </Box>
              <Box>
                <Text>End Date</Text>
                <Text fontWeight={'light'}>
                  {campaign?.claim_period.end &&
                    format(new Date(campaign?.claim_period.end), 'yyyy MMM dd')}
                </Text>
              </Box>
            </HStack>
            <Text>Maximum Usage Time</Text>
            <Text fontWeight={'light'}>{campaign?.usage_limit}</Text>
          </Box>
        </Box>
        <Divider orientation={'vertical'} h={[0, 0, 'calc(100vh - 200px)']} />
        <Center flex={1} w={'full'} alignItems={'start'} px={'20px'}>
          <Box w={'full'} maxW={'400px'} pt={'5px'}>
            <Text
              mb={'20px'}
              color={'coap.blue.5'}
              textAlign={'center'}
              fontWeight={'light'}
              fontSize={'2xl'}
            >
              COAP NFT Details
            </Text>
            <Center mb={'20px'}>
              <Image
                w={'250px'}
                h={'250px'}
                maxW={'250px'}
                maxH={'250px'}
                border={'0.1px solid lightgrey'}
                draggable={false}
                src={campaign?.token_uri}
              />
            </Center>
            <Text
              fontSize={'2xl'}
              fontWeight={'bold'}
              w={'full'}
              borderRadius={'1000px'}
              bgColor={'coap.blue.5'}
              textAlign={'center'}
              color={'#ffffff'}
              p={'5px 50px 5px 50px'}
              mb={'20px'}
            >
              {tokenInfo?.token_name}
            </Text>
            <Grid
              w={'full'}
              templateColumns={['1fr', '1fr', '1fr 1fr']}
              gap={2}
              mb={'20px'}
            >
              {tokenInfo?.token_attributes.map((el, i) => {
                return (
                  <GridItem key={i} colSpan={[1, 1, i === 0 ? 2 : 1]}>
                    <Box
                      borderRadius={'10px'}
                      borderWidth={'1px'}
                      borderStyle={'solid'}
                      borderColor={'coap.blue.5'}
                      bgColor={'coap.blue.2'}
                      textAlign={'center'}
                      p={'5px 10px 5px 10px'}
                      flex={1}
                    >
                      <Text
                        color={'coap.blue.5'}
                        fontWeight={'semibold'}
                        mb={'5px'}
                      >
                        {el.trait_type}
                      </Text>
                      <Text color={'grey'}>{el.value}</Text>
                    </Box>
                  </GridItem>
                );
              })}
            </Grid>
            <Box w={'full'} mb={'20px'}>
              <Text>COAP Token Details</Text>
              <Text fontWeight={'light'}>
                {campaign?.token_info.token_description}
              </Text>
            </Box>
            <Box>
              <Text>Email address</Text>
              <Text fontWeight={'light'}></Text>
            </Box>
            <form onSubmit={formik.handleSubmit}>
              <FormControl mb={'20px'} isInvalid={!!formik.errors.email}>
                <Input
                  id={'email'}
                  name={'email'}
                  autoComplete={'email'}
                  autoCorrect={'off'}
                  borderRadius={0}
                  size={'sm'}
                  placeholder={'Enter email address to claim the token'}
                  disabled={claimMintLinkStatus === AsyncState.PENDING}
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
                <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
              </FormControl>
              <Center>
                <ConfirmButton
                  label={'Claim COAP Token'}
                  type={'submit'}
                  isLoading={claimMintLinkStatus === AsyncState.PENDING}
                />
              </Center>
              <Center>
                <Text fontSize={'xs'} textAlign={'center'} p={'16px'}>
                  By proceeding, you agree to our{' '}
                  <a
                    href="https://s3.ap-southeast-1.amazonaws.com/static.coap.app/terms_of_use.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of Use
                  </a>{' '}
                  and{' '}
                  <a
                    href="https://s3.ap-southeast-1.amazonaws.com/static.coap.app/privacy_policy.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                  .
                </Text>
              </Center>
            </form>
          </Box>
        </Center>
      </Stack>
    </Container>
  );
}
